import { Button, Nav, contents, spacing } from "ms-ui";
import { useURI } from "ms_router";
import styled from "styled-components";
import { Leave } from "./LeaveTitle";

const LeaveComplete = () => {
  const { navigate } = useURI();
  return (
    <>
      <Nav title="회원 탈퇴 완료" />
      <StContainer>
        <Leave />
        <Button
          style={{ marginTop: "40px" }}
          onClick={() => navigate(`/`, { replace: true })}
          radius={"5"}
          color={`primary`}
          outline={true}
          size="rg"
          width="100%"
        >
          모두의셔틀 홈으로
        </Button>
      </StContainer>
    </>
  );
};

export default LeaveComplete;

const StContainer = styled.div`
  height: calc(100dvh - 60px);
  width: 100%;
  background-color: ${contents.secondary.value};
  padding: ${spacing[16].value};
  padding-top: ${spacing[32].value};

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${spacing[8].value};
`;
