import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Subtitle2, Body1 } from "ms-ui";
import styled from "styled-components";

export const Leave = observer(() => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <img
            src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
              process.env.REACT_APP_STAGE === "production" ? "prod" : "dev"
            }/image/icon/success.svg`}
            width={`fit-content`}
            height={`fit-content`}
          />
        </StDiv2>
        <StDiv3>회원 탈퇴가 완료되었습니다!</StDiv3>
        <StDiv4>
          보다 나은 모두의셔틀로
          <br />
          다시 만날 수 있길 바라요~
        </StDiv4>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[52].value} 0px ${spacing[40].value} 0px;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[12].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Subtitle2};
  align-self: stretch;
  color: ${contents.primary.value};
  text-align: center;
`;

const StDiv4 = styled.div`
  ${Body1};
  align-self: stretch;
  color: ${contents.subtitle.value};
  text-align: center;
`;
